import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";


export default class PhotoAlbum {
    constructor() {
        this.http = new HttpHandler();
    }

    getPhotoAlbumByUsrId() {
        return this.http.post(`/api/photoAlbum/data`, null, HttpConstant.APPLICATION_JSON_REQ);
    }

    getPhotoAlbumSegmentByPaId(data) {
        return this.http.post(`/api/photoAlbum/segment`, data, HttpConstant.FORM_DATA_REQ);
    }

    delPhotoAlbum(data) {
        return this.http.post(`/api/photoAlbum/delPhotoAlbum`, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    insPhotoAlbum(data) {
        return this.http.post(`/api/photoAlbum/insPhotoAlbum`, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    delPhotoAlbumAssets(data) {
        return this.http.post(`/api/photoAlbum/delPhotoAlbumAssets`, data, HttpConstant.APPLICATION_JSON_REQ);
    }
}