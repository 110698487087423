<template>
  <div>
    <el-button type="primary" icon="el-icon-folder-add" @click="dialogVisible = true">添加</el-button>
    <el-divider></el-divider>
    <el-row>
      <el-col v-for="(iter , index) in photoAlbumList" :key="index">
        <div @click="jump(iter.paId)">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="font-weight">{{ iter.paName }}</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click.stop.prevent="del(iter.paId)">删除
              </el-button>
            </div>
            <div class="text item font-size-14">
              {{ iter.paDesc }}
            </div>
            <p><span class="time">{{ iter.paCreationTime }}</span></p>
          </el-card>
        </div>
      </el-col>
    </el-row>

    <el-dialog
        title="添加相册"
        :visible.sync="dialogVisible"
        :fullscreen="true"
        :before-close="handleClose">
      <span>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="相册名称">
            <el-input v-model="form.paName" placeholder="请输入内容"/>
          </el-form-item>
          <el-form-item label="相册描述">
            <el-input v-model="form.paDesc" placeholder="请输入内容"/>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="add">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import PhotoAlbum from "../../../request/expand/photoAlbum";
import ExpandIndex from "../../../request/expand/index";

export default {
  name: "souvenir",
  data() {
    return {
      ask: new PhotoAlbum(),
      eAsk: new ExpandIndex(),
      heightI: "100pv",
      photoAlbumList: [],
      dialogVisible: false,
      form: {
        "paName": "",
        "paDesc": ""
      }
    }
  },
  created() {
    this.heightI = window.innerHeight - 60

    this.loadData();
  },
  methods: {
    loadData() {
      this.ask.getPhotoAlbumByUsrId().then(res => {
        this.photoAlbumList = res
      })
    },
    del(paId) {
      this.$confirm('确认删除？')
          .then(_ => {
            this.ask.delPhotoAlbum({"paId": paId}).then(res => {
              if (res.code == 200) {
                this.$message({
                  showClose: true,
                  message: '删除成功!',
                  type: 'success'
                });
                this.loadData();
                return
              }
            })
          })
          .catch(_ => {
          });
    },
    add() {
      this.ask.insPhotoAlbum(this.form).then(res => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: '新增成功',
            type: 'success'
          });
          this.form.paName = ""
          this.form.paDesc = ""
          this.dialogVisible = false
          this.loadData();
        }
          return
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    jump(id) {
      this.eAsk.appToken({"time": new Date().getTime()}).then(res => {
        if (res.code == 200) {
          this.$router.push({
            name: 'souvenir_segment',
            query: {
              paId: id,
              "appToken": res.result
            }
          })
        }
      })

    }
  },
  destroyed() {
    //页面销毁，同时也销毁video.js对象
  },
  mounted() {

  }
}
</script>

<style scoped>
.time {
  float: right;
  text-align: right;
  font-size: 14px;
  color: rgba(94, 54, 54, 0.4)
}

.font-weight {
  font-weight: bold;
  font-size: 18px;
}

.font-size-14 {
  font-size: 14px;
}

.box-card {
  margin-top: 5px;
}
</style>